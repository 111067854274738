import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MySwiper from "../components/swiper/swiper"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import tstl1 from "../images/1.jpg"
import tstl2 from "../images/2.jpg"
import tstl3 from "../images/3.jpg"
import Modal from "../components/modal/modal"
import logo from "../images/logo.png"


class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div
          className="container-fluid hero"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage:
              "url(https://cdn.shopify.com/s/files/1/0062/0376/8945/files/timelessclassiclegendary_mockup_Front_Couples_Black_1512x.png?v=1560109630)",
          }}
        >


            <div class="intro-body">
        <div class="masthead">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mx-auto">
                        <h1 class="brand-heading"><strong>REPRESENT YOUR FOREVER</strong></h1>
                        <p class="intro-text text-center">We are TIMELESS. We are CLASSIC. We are LEGENDARY</p></div>
                </div>
            </div>
        </div>
    </div>
         {/* <div className="row">
            <div className="col">
              <div className="text-center">
                <h4
                  data-aos="fade"
                  data-aos-duration="600"
                  data-aos-delay="600"
                >
                  A website template by WebriQ
                </h4>
                <h1
                  data-aos="fade-down"
                  data-aos-duration="600"
                  data-aos-delay="1000"
                >
                  We create awesome <br />
                  websites.
                </h1>
                <Modal className="btn btn-primary main-cta text-uppercase" />
              </div>
            </div>
          </div> */}
        </div>
            <section id="about" class="content-section text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 mx-auto"><img src={logo}/>
                    <h2 class="text-white text-space h2-space"><strong>ABOUT AEONIK</strong></h2>
                    <p class="text-white">Founded in Colorado Springs by Nate Sheppard, Aeonik Threads is a premium lifestyle clothing brand whose designs are created for you to confidently represent your forever.<br/></p>
                    <p class="text-white">Aeonik is synonymous to eon, meaning forever, everlasting, long-lasting. Aeonik Threads is a premium lifestyle clothing brand that is committed by the passion to inspire and empower those who wear our clothing, as well as the ones
                        who see them.<br/></p>
                    <p class="text-white"><strong>We are an independent apparel brand committed to inspire and encourage individuals.</strong></p>
                </div>
            </div>
        </div>
    </section>
     {/*}   <div className="our-work pt-5">
          <div className="subheader">
            <h3 data-aos="fade-down" data-aos-duration="600">
              Our Works
            </h3>
            <hr data-aos="fade" className="mx-auto" />
            <p
              className="text-black-50 subheading"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Lorem ipsum dolor sit amet blanditiis accusamus perferendis libero
              amet nisi accusamus.
            </p>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-delay="100"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      className="img-fluid"
                      loading="lazy"
                      alt="corporate"
                      src="https://images.unsplash.com/photo-1505322747495-6afdd3b70760?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-trophy text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Corporate Design</h5>
                      <p>Design</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade-down"
                  data-aos-duration="600"
                  data-aos-delay="200"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      alt="social media"
                      className="img-fluid"
                      loading="lazy"
                      src="https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-chart text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Social Media Marketing</h5>
                      <p>Marketing</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-delay="300"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      alt="website"
                      className="img-fluid"
                      loading="lazy"
                      src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-layers text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Website Development</h5>
                      <p>Development</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="our-services">
          <div className="subheader">
            <h3 data-aos="fade-down" data-aos-duration="600">
              Our Services
            </h3>
            <hr data-aos="fade" />
            <p
              className="text-black-50 subheading"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis accusamus perferendis liberoa dipisicing accusantium
              nisi.
            </p>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-3">
                <div
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-delay="100"
                  className="info text-center"
                >
                  <i className="icon-trophy" />
                  <h5>Brand Identity</h5>
                  <p className="text-black-50">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias id volume amet.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-delay="200"
                  className="info text-center"
                >
                  <i className="icon-layers" />
                  <h5>Web Development</h5>
                  <p className="text-black-50">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias id volume amet.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-delay="300"
                  className="info text-center"
                >
                  <i className="icon-screen-smartphone" />
                  <h5>Mobile Application</h5>
                  <p className="text-black-50">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias id volume amet.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-delay="400"
                  className="info text-center"
                >
                  <i className="icon-chart" />
                  <h5>SEO</h5>
                  <p className="text-black-50">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias id volume amet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      {/*  <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="info pr-0 pr-md-5">
                  <h5
                    data-aos="fade-down"
                    data-aos-delay="100"
                    className="text-primary"
                  >
                    WHO WE ARE
                  </h5>
                  <hr
                    data-aos="fade"
                    data-aos-delay="150"
                    className="text-left"
                  />
                  <h3
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="200"
                    className="my-4"
                  >
                    Do Things That Matters. Plan. Create. Grow.
                  </h3>
                  <p
                    className="text-black-50"
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="300"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                    autem temporibus blanditiis accusamus perferendis libero
                    accusantium nisi itaque tempore, harum aliquid aut, sapiente
                    dolor tenetur. Tempora corrupti suscipit delectus
                    perspiciatis!Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Nostrum, voluptatibus.
                  </p>
                  <p
                    className="text-black-50"
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="400"
                  >
                    Tempora corrupti suscipit delectus perspiciatis!
                  </p>
                </div>
              </div>
              <div className="col d-md-flex align-items-md-center">
                <img
                  className="img-fluid"
                  data-aos="fade"
                  alt="Tempora"
                  data-aos-duration="600"
                  data-aos-delay="800"
                  src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=768&amp;q=80&amp;h=1020"
                />
              </div>
            </div>
          </div>
        </div>*/}
            <section id="download" class="download-section content-section text-center">
        <div class="container">
            <div class="col-lg-8 mx-auto">
                <h1 class="text-space text-white"><strong>VISIT OUR STORE</strong></h1>
                <p className="text-white">Fashion trends come and go, but we are AEONIK. Our mission is to Build A Legacy. <br/>From the streets to the corporate suites. </p><a class="btn btn-primary1 btn-lg btn-default1" href="https://aeonikthreads.com/collections/all" target="blank_">VISIT STORE PAGE</a></div>
        </div>
        </section>
        <section id="contact" class="content-section text-center">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 mx-auto"><img src={logo}/>
                    <h2 class="text-white text-space h2-space"><strong>CHECK&nbsp;US OUT</strong></h2>
                    <p class="text-white">Check out our social media pages and feel free to follow or leave a comment on our social media pages.&nbsp;</p>
                    <ul class="list-inline banner-social-buttons">
                        <li class="list-inline-item">&nbsp;<a class="btn btn-primary1 btn-lg btn-default1" href="https://aeonikthreads.com/collections/all" target="blank_"><i class="fa fa-facebook fa-fw"></i>FACEBOOK</a></li>
                        <li class="list-inline-item">&nbsp;<a class="btn btn-primary1 btn-lg btn-default1" href="https://twitter.com/AeonikThreads" target="blank_"><i class="fa fa-twitter fa-fw"></i><span class="network-name"><strong>TWITTER</strong></span></a></li>
                        <li class="list-inline-item">&nbsp;<a class="btn btn-primary1 btn-lg btn-default1" href="https://www.instagram.com/aeonikthreads/" target="blank_"><i class="fa fa-instagram fa-fw"></i><span class="network-name"><strong>INSTAGRAM</strong></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
        <section id="download" class="legacy-section content-section text-center">
        <div class="container">
            <div class="col-lg-8 mx-auto">
                <h1 class="text-space text-white"><strong>BUILD&nbsp;YOUR LEGACY</strong></h1>
                <p class="text-white">Provide you with quality products with inspiring and empowering designs that will make an impression to last forever.</p><a class="btn btn-primary1 btn-lg btn-default1" href="https://www.facebook.com/pg/aeonikthreads/shop/?ref=page_internal"
                    target="blank_">VIEW OUR CATALOG</a></div>
        </div>
    </section>
   <section class="newsletter">
        <h2 class="text-white text-center text-space">SUBSCRIBE TO OUR NEWSLETTER</h2>
        <p class="text-white text-center">Subscribe to our newsletter for the latest products news and releases</p>
        <div class="container">
            <form class="c-form contact-clean row justify-content-center" method="post">
                <div class="form-group"><input class="form-control" type="text" name="name" placeholder="Full Name"/></div>
                <div class="form-group"><input class="form-control" type="email" name="email" placeholder="Email"/><small class="form-text  text-white text-center">&nbsp; &nbsp; &nbsp; Please enter a correct email address.</small></div>
                <div class="form-group"><input class="form-control" type="tel" name="Phone" placeholder="Phone"/></div>
                <div class="form-group c-button"><button class="btn btn-primary1 btn-default1 btn-lg" type="button"><strong>SUBSCRIBE</strong></button></div>
            </form>
        </div>
  </section>
        {/*<div className="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="subheader">
                  <h3>What our client's are saying</h3>
                  <hr className="mx-auto" />
                </div>
              </div>
              <div className="col">
                <MySwiper slidesperview={1}>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl1}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl2}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl3}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                </MySwiper>
              </div>
            </div>
          </div>
        </div> */}
        {/*<div className="call-to-action">
          <div className="subheader">
            <h3 className="text-white">Let's do more together</h3>
            <p className="text-white-50 subheading">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis accusamus perferendis libero accusantium nisi.
            </p>
            <Modal className="text-white btn btn-primary btn-inverted mt-2 text-uppercase" />
          </div>
        </div> */}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
